<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent  scrollable   max-width="520px">
                <v-card width="600px;" id="form_card">
                    <v-card-title><v-icon left>mdi-calendar-today</v-icon>{{ formTitle }}</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text style="height: auto;">
                         <br>
                        <form-validation-messages :messages="apiValidationMessages"></form-validation-messages>
                        <form-submit-messages :messages="apiMessages" :errorMessages="apiErrorMessages"></form-submit-messages>
                        <v-form id="form_update_timeline_item" ref="form" v-model="valid" lazy-validation>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                                <v-select
                                    v-model="selected_timeline_type"
                                    :items="timeline_types"
                                    label="Izberi tip urnika"
                                    clearable
                                    chips
                                    deletable-chips
                                    prepend-icon="mdi-monitor-star"
                                    item-value="id"
                                    item-text="name"
                                    no-data-text="Ni podatkov za prikaz"
                                    :rules="selected_timeline_type_rules"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" v-if="selected_timeline_type !== null && selected_timeline_type == 1">
                                <v-select
                                    v-model="selected_day"
                                    :items="days"
                                    label="Izberi dan"
                                    chips
                                    deletable-chips
                                    clearable
                                    prepend-icon="mdi-monitor-star"
                                    item-value="id"
                                    item-text="name"
                                    dense
                                    no-data-text="Ni podatkov za prikaz"
                                    :rules="(selected_timeline_type !== null && selected_timeline_type == 1) ? selected_days_rules : []"
                                ></v-select>
                            </v-col>

                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" v-if="selected_timeline_type !== null && selected_timeline_type == 2">
                                <!-- začetni datum -->
                                <v-menu
                                    ref="start_date_menu"
                                    :close-on-content-click="false"
                                    v-model="start_date_menu"
                                    :nudge-right="40"
                                    :return-value.sync="restriction_start_date"
                                    
                                    transition="scale-transition"
                                    offset-y
                                    
                                    min-width="290px"
                                    >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                        v-on="on"
                                            v-model="restriction_start_date_formatted"
                                            label="Datum od"
                                            prepend-icon="mdi-calendar-today"
                                            readonly
                                            clearable
                                            :rules="(selected_timeline_type !== null && selected_timeline_type == 2) ? restriction_start_date_rules : []"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker color="secondary" @input="startDateMenuSave(restriction_start_date)" v-model="restriction_start_date" no-title scrollable locale="sl-si">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" v-if="selected_timeline_type !== null && selected_timeline_type == 2">
                                <!-- končni datum -->
                                <v-menu
                                    ref="end_date_menu"
                                    :close-on-content-click="false"
                                    v-model="end_date_menu"
                                    :nudge-right="40"
                                    :return-value.sync="restriction_end_date"
                                    
                                    transition="scale-transition"
                                    offset-y
                                    
                                    min-width="290px"
                                    >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                        v-on="on"
                                            v-model="restriction_end_date_formatted"
                                            label="Datum do"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            clearable
                                            :rules="(selected_timeline_type !== null && selected_timeline_type == 2) ? restriction_end_date_rules : []"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker color="secondary" @input="endDateMenuSave(restriction_end_date)" v-model="restriction_end_date" no-title scrollable locale="sl-si">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                                <v-select
                                    v-model="selected_device"
                                    :items="devices"
                                    label="Izberi napravo"
                                    clearable
                                    chips
                                    deletable-chips
                                    prepend-icon="mdi-monitor-star"
                                    item-value="id"
                                    item-text="name"
                                    dense
                                    no-data-text="Ni podatkov za prikaz"
                                    :rules="selected_device_rules"
                                ></v-select>  
                            </v-col>

                            <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                                <v-menu
                                    ref="start_time_menu"
                                    v-model="start_time_menu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    :return-value.sync="restriction_start_time"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="restriction_start_time"
                                        label="Začetni čas"
                                        prepend-icon="mdi-clock-outline"
                                        readonly
                                        v-on="on"
                                        clearable
                                        :rules="start_time_rules"
                                    ></v-text-field>
                                    </template>
                                    <v-time-picker
                                    color="secondary"
                                    format="24hr"
                                    v-if="start_time_menu"
                                    v-model="restriction_start_time"
                                    
                                    @click:minute="$refs.start_time_menu.save(restriction_start_time)"
                                    ></v-time-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                                <v-menu
                                    ref="end_time_menu"
                                    v-model="end_time_menu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    :return-value.sync="restriction_end_time"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="restriction_end_time"
                                        label="Končni čas"
                                        prepend-icon="mdi-clock-outline"
                                        readonly
                                        v-on="on"
                                        clearable
                                        :rules="end_time_rules"
                                    ></v-text-field>
                                    </template>
                                    <v-time-picker
                                    color="secondary"
                                    format="24hr"
                                    v-if="end_time_menu"
                                    v-model="restriction_end_time"
                                    
                                    @click:minute="$refs.end_time_menu.save(restriction_end_time)"
                                    ></v-time-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                                <v-checkbox class="ti" v-model="timeline_active" :label="`Status časovnice: ${checkboxTimelineStatusLabel}`"></v-checkbox>
                            </v-col>

                            <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                                <v-textarea
                                outlined
                                name="input-7-4"
                                label="Opombe"
                                v-model="note"
                                counter
                                required
                                clearable
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        </v-form>

                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn :disabled="disableSubmit" :loading="loading" color="success" text @click="submitForm()"><v-icon left>mdi-arrow-right</v-icon>Shrani</v-btn>
                        <v-btn color="error" text  @click="cancel()"><v-icon left>mdi-close</v-icon>{{ cancelText }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDate, isMobileDevice, getUserApplicationPermissions, checkPermissions } from '@/helpers/utilities'
const FormValidationMessages = () => import('@/components/FormValidationMessages.vue')
const FormSubmitMessages = () => import('@/components/FormSubmitMessages.vue')

export default {
    name: 'timeline-item-dialog',
    components: {
        FormValidationMessages,
        FormSubmitMessages
    },

    data: () => ({
        valid: true,
        formTitle: "",
        dialog: false,
        item: null,
        loading: false,
        disableSubmit: false,
        cancelText: "Prekliči",

        timeline_types: [
            {
                id: 1,
                name: "Dnevni (ponedeljek, torek, ...)"
            },
            {
                id: 2,
                name: "Datumski (od - do)"
            }
        ],

        timeline_active: true,
        note: "",

        restriction_start_time:null,
        restriction_end_time: null,

        restriction_start_date: null,
        restriction_end_date:null,
        restriction_start_date_formatted: null,
        restriction_end_date_formatted: null,
        start_date_menu: false,
        end_date_menu:false,

        start_time_menu:false,
        end_time_menu:false,

        devices: [],
        days: [
            {
                id: 1,
                name: "Ponedeljek"
            },
            {
                id: 2,
                name: "Torek"
            },
            {
                id: 3,
                name: "Sreda"
            },
            {
                id: 4,
                name: "Četrtek"
            },
            {
                id: 5,
                name: "Petek"
            },
            {
                id: 6,
                name: "Sobota"
            },
            {
                id: 7,
                name: "Nedelja"
            }
        ],

        selected_device: null,
        selected_day: null,
        selected_timeline_type: null,

        backendMessages: [],
        backendErrorMessages: [],
        backendValidationMessages:[],

        currentDate: null,

        selected_timeline_type_rules: [v => !!v || 'Polje tip urnika je obvezno'],
        
        selected_days_rules: [
            v => !!v || 'Polje dan je obvezno'
        ],
        
        selected_device_rules: [ 
            v => !!v || 'Polje naprava je obvezno',
        ],
        
        start_time_rules:  [v => !!v || 'Polje začetni čas je obvezno'],
        end_time_rules:  [v => !!v || 'Polje končni čas je obvezno'],
        
        restriction_start_date_rules:  [v => !!v || 'Polje datum od je obvezno'],
        restriction_end_date_rules:  [v => !!v || 'Polje datum do je obvezno'],
    }),

    watch: {
        restriction_start_date (val) {
            this.restriction_start_date_formatted = formatDate(val)
        },

        restriction_end_date (val) {
            this.restriction_end_date_formatted = formatDate(val)
        },

        selected_timeline_type(val) {
            window.console.log(val)
            if(val == 1) {
                this.restriction_start_date = null
                this.restriction_end_date = null
            } 

            if(val == 2) {
                this.selected_day = null
            }
        }
    },

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        apiMessages() {
            return this.backendMessages;
        },

        apiErrorMessages() {
            return this.backendErrorMessages 
        },

        apiValidationMessages() {
            return this.backendValidationMessages
        },

        checkboxTimelineStatusLabel() {
            if(this.timeline_active == true) {
                return "Aktivna"
            }

            return "Neaktivna"
        }
    },

    methods: {
        submitForm() {
            var vm = this
            if(this.$refs.form.validate()) {

                this.backendValidationMessages = []
                this.backendMessages = []
                this.backendErrorMessages = []

                vm.$store.commit('SET_PROGRESS', true)
                vm.loading = true
                vm.$store.commit('SET_LOADER_TEXT', 'Posodobitev urnika je v teku...')
                vm.$store.commit('SET_LOADER', true)
                vm.$store.dispatch('UPDATE_TURNSTILE_TIMELINE', {
                    id: vm.item.id,
                    uuid: vm.item.uuid,
                    application_id: vm.item.application_id,
                    application_uuid: vm.item.application_uuid,
                    timeline_type: vm.selected_timeline_type,
                    selected_device: vm.selected_device,
                    selected_day: vm.selected_day,
                    restriction_start_date: vm.restriction_start_date,
                    restriction_end_date: vm.restriction_end_date,
                    restriction_start_time: vm.restriction_start_time,
                    restriction_end_time: vm.restriction_end_time,
                    note: vm.note,
                    active: (vm.timeline_active == true || vm.timeline_active == 1 ) ? 1 : 0
                })
                .then(response => {
                    //window.console.log(response)
                    vm.$store.commit('snackbarDisplay', false)
                    vm.$store.commit('snackbarText', "Urnik št. " + vm.item.id + " je bil uspešno posodobljen.")
                    vm.$store.commit('snackbarMode', '')
                    vm.$store.commit('snackbarTimeout', 7000)
                    vm.$store.commit('snackbarDisplay', true)

                    vm.clearForm()
                    vm.backendMessages.push("Urnik št. " + vm.item.id + " je bil uspešno posodobljen.");
                    this.cancelText = "Zaključi";

                })
                .catch(error => {
                    console.log('CREATE_TURNSTILE_TIMELINE error')
                    console.log(error)
                    vm.$store.commit('snackbarDisplay', false)
                    vm.$store.commit('snackbarMode', 'multi-line')
                    vm.$store.commit('snackbarText', 'Pri posodabljanju urnika ' + vm.item.id + ' je prišlo do napake. Prosimo poskusite ponovno.')
                    vm.$store.commit('snackbarDisplay', true)

                    if(error.response.status === 422) {
                        window.console.log(error.response.data.errors)
                        for (const property in error.response.data.errors) {
                            //window.console.log(`${property}: ${error.response.data.errors[property]}`);
                            var propertyData = error.response.data.errors[property];
                            //window.console.log(propertyData);
                            propertyData.forEach(message => {
                                vm.backendValidationMessages.push(message);
                            })

                            setTimeout(() => {
                                vm.$vuetify.goTo('#form_card', {
                                    duration: 1000,
                                    offset: 0,
                                    easing: 'easeInOutCubic'
                                })
                            }, 200)
                        }
                    } else {
                        vm.backendErrorMessages.push('Ups... pri posodabljanju urnika št. ' + vm.item.id + ' je prišlo do napake. Poskusite ponovno.')
                        setTimeout(() => {
                            vm.$vuetify.goTo('#form_card', {
                                duration: 1000,
                                offset: 0,
                                easing: 'easeInOutCubic'
                            })
                        }, 200)
                    }

                })
                .finally(() => {
                    setTimeout(() =>{
                        vm.loading = false;
                        vm.$store.commit('SET_PROGRESS', false)
                        vm.$store.commit('SET_LOADER', false)
                        vm.$store.commit('SET_LOADER_TEXT', '')
                    },600)
                })
            } 
            else
            {
                setTimeout(() => {
                    vm.$vuetify.goTo('#form_card', {
                        duration: 1000,
                        offset: 0,
                        easing: 'easeInOutCubic'
                    })
                }, 200)
            } 
        },

        cancel() {
            this.clearForm()
            this.item = null
            this.loading = false
            this.disableSubmit = false
            this.dialog = false
            this.cancelText = "Prekliči"

            EventBus.$emit('REFRESH_TURNSTILE_TIMELINES_DATA_TABLE', null);
        },

        clearForm() {
            this.$refs.form.resetValidation();
            this.setDefaultFormValues()
            this.clearBackendMessages()
        },

        setDefaultFormValues() {
            
        },

        clearBackendMessages() {
            this.backendValidationMessages = []
            this.backendMessages = []
            this.backendErrorMessages = []
        },

        checkApplicationPermissions(requiredPermissions) {
            return checkPermissions(requiredPermissions, getUserApplicationPermissions())
        },

        startDateMenuSave(value) {
            this.$refs.start_date_menu.save(value)
            this.start_date_menu = false
        },

        endDateMenuSave(value) {
            this.$refs.end_date_menu.save(value)
            this.end_date_menu = false
        },

        setParametersData() {
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
            vm.$store.dispatch('PARKING_GATES_PARAMETERS_DATA')
            .then(response => {
                //console.log(response)
                //console.log(response.data)
                this.devices = response.data.turnstiles
            })
            .catch(error => {
                console.error("### setParametersData ###");
                console.log(error)
            })
            .finally(() => {
                setTimeout(() => {
                    vm.$store.commit('SET_PROGRESS', false)  
                }, 700)
            })
        },
    },

    created() {
        var vm = this
        EventBus.$on('EDIT_TURNSTILE_TIMELINE_ITEM', function(data) {
            window.console.log(data)
            vm.currentDate = getCurrentDate()

            vm.setParametersData()
            vm.restriction_start_date = getCurrentDate()
            vm.restriction_end_date = getCurrentDate()
            vm.restriction_start_date = null
            vm.restriction_end_date = null
            vm.restriction_start_time = "00:01"
            vm.restriction_end_time = getCurrentTime()
            vm.item = data

            vm.selected_timeline_type = data.timeline_type
            vm.restriction_start_date = data.restriction_start_date
            vm.restriction_end_date = data.restriction_end_date
            vm.restriction_start_time = data.restriction_start_time
            vm.restriction_end_time = data.restriction_end_time
            vm.selected_day = data.day 
            vm.timeline_active = data.active
            vm.note = data.note
            vm.selected_device = data.parking_gate_id

            vm.dialog = true;

            vm.formTitle = "Urnik št. " + vm.item.id
            //window.console.log("Item:")
            //window.console.log(vm.item)
        });

    },

    mounted() {

    },

    beforeDestroy() {
        EventBus.$off('EDIT_TURNSTILE_TIMELINE_ITEM');
    },

    destroyed() {

    }

}

</script>

<style scoped>

</style>